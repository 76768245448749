import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Nav } from "../../components";
import { WorkSectionHeader } from "../../components";
import GlobalLayout from "../../layouts/GlobalLayout";
import successGif from "../../images/work/cubits/success.gif";
import listGif from "../../images/work/cubits/list.gif";
import { Link } from "gatsby";

const cubits = () => {
  const imgStyleOutline = {
    boxShadow: "0px 4px 32px #00000015",
    maxWidth: "1280px",
    // marginLeft: '50vw',
    placeSelf: "center",
    border: "1px solid black",
    marginTop: "3.33em",
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workType="Product"
        workName="Cubits: Digital Diamonds"
        what="Hyperdimensional material foundry specializing in energy condensation onto Bitcoin Inscriptions."
        who="Confidential"
        role="Brand & Product Design"
        shoutout="HongKong97, Wags, &amp; Float"
      />
      <ShowcaseContainer>
        <LinkOut target="_blank" href="https://cubits.wtf">
          cubits.wtf
        </LinkOut>
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/logoSlide.svg"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/auctionHomeOrange.png"
        />
        <img
          alt="lolololololololololololololololol"
          style={{ imgStyleOutline }}
          src={listGif}
        />

        <img
          alt="lolololololololololololololololol"
          style={{ imgStyleOutline }}
          src={successGif}
        />

        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/CubitListPink.png"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/signIn1.png"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/CubitListBlue.png"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/WpHome.png"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/coordFail.png"
        />
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/cubits/coordSucc.png"
        />
      </ShowcaseContainer>
    </GlobalLayout>
  );
};

const ShowcaseContainer = styled.section`
  display: flex;
  flex-flow: column;
  padding: 3.33em 0;
  justify-content: center;
`;

const LinkOut = styled.a`
  border: 1px solid ${({ theme }) => theme.grids};
  color: ${({ theme }) => theme.text};
  text-align: center;
  max-width: 300px;
  margin: 0 auto;

  padding: 0.99rem 2rem 1.22rem 2rem;
  text-decoration: underline;
  border-radius: 1rem 0.5rem 1rem 0.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.accent};
  }
`;
export default cubits;
